<template>
  <div class='flex flex-row justify-start items-stretch'>
    <input
      v-model='inputValue'
      :placeholder='placeholder'
      @input='blockKoreanInput($event)'
      class='border py-1 px-2 rounded block w-1/2'>
    <div class='w-1/2 flex flex-row justify-start items-stretch'>
      <div 
        v-if='showCustomInput'
        class='flex flex-row justify-start items-stretch w-full'>
        <span class='block px-1 py-1'>@</span>
        <input
          v-model='customInputValue'
          class='border py-1 px-2 rounded block w-full'
          type='text'
          :placeholder='placeholder'>
      </div>
      <select
        v-model='selectValue'
        class='border py-1 px-2 rounded block w-full'>
        <option disabled>select email</option>
        <option v-for='selectableOption in selectableOptions'
          :key='`selectableOption-${selectableOption.id}-${selectableOption.value}`'
          :value='selectableOption.value'>
          {{selectableOption.title}}
        </option>
      </select>

    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldText',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      inputValue: '',
      selectValue: '',
      customInputValue: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) { 
          // need to separate by input part, and the domain of the email address that is set with the select part
          if (newVal.includes('@')) {
            let splitVal = newVal.split('@')
            this.inputValue = splitVal[0]
            let index = this.selectableOptions.findIndex(selectableOption => selectableOption.value === splitVal[1])
            if (index > -1) {
              this.selectValue = splitVal[1]
            } else {
              this.selectValue = 'other'
              this.customInputValue = splitVal[1]
            }
          } else {
            this.inputValue = newVal
          }
        }
      }, 
      immediate: true,
    },
    updatedInternalVal: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:inputValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
    selectableOptions () {
      return this.metaData && this.metaData.selectableOptions ? this.metaData.selectableOptions : []
    },
    showCustomInput () {
      return this.selectValue === 'other'
    },
    updatedInternalVal () {
      return (this.showCustomInput) 
            ? `${this.inputValue}@${this.customInputValue}`
            : `${this.inputValue}@${this.selectValue}`
    },
  },
  methods: {
    blockKoreanInput (event) {
      if (this.blockKorean) {
        event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
      }
    },
  }
}
</script>